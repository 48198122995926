export enum ShippingType {
  DELIVER = 'Bezorgen',
  DELIVERED = 'Bezorgd',
  PICK_UP = 'Afhalen',
  PICKED_UP = 'Afgehaald',
  DELIVER_BY_SUPPLIER = 'Bezorgen door leverancier',
  DELIVERED_BY_SUPPLIER = 'Bezorgd door leverancier'
}


export enum OrderTab {
  UPCOMING = 'upcoming',
  ON_HOLD = 'onHold',
  HISTORY = 'history'
}
